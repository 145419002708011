<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-md-12 d-flex justify-content-end">
        <router-link to="/generate-invoices" class="btn btn-sm btn-success">
          <i class="fa fa-plus" aria-hidden="true"></i> Generate
          invoice</router-link>
      </div>
    </div>
    <nav class="nav">
      <a class="nav-link  btn btn-outline-primary mr-3 " :class="{ active: activeTab === 'tab1' }"
        @click="changeTab('tab1')" href="#">New({{ tableData.length }})</a>
      <a class="nav-link btn btn-outline-warning mr-3" @click="changeTab('tab2')"
        :class="{ active: activeTab === 'tab2' }" href="#">Ongoing Invoice({{ ongoing.length }})</a>
      <a class="nav-link btn btn-outline-success" :class="{ active: activeTab === 'tab3' }" @click="changeTab('tab3')"
        href="#">Complete Invoice({{ invoice_complete.length }})</a>
    </nav>
    <div class="row" v-if="activeTab == 'tab1'">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="tableData" :fields="fields" responsive="sm" :per-page="perPage"
                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">
                <template v-slot:cell(Edit)="row">
                  <router-link :to="`/edit-pharmacy-invoice/${row.item.id}`" class="btn btn-sm btn-warning">
                    <i class="fa fa-pen" aria-hidden="true" title="Edit"></i></router-link>
                </template>

                <template v-slot:cell(clientName)="row">
                  {{ row.item.clientFirstName + " " + row.item.clientLastName }}
                </template>

                <template v-slot:cell(employeeName)="row">
                  {{
                  row.item.employeeFirstName + " " + row.item.employeeLastName
                  }}
                </template>

                <template v-slot:cell(DueDate)="row">
                  <span class="badge badge-pill badge-danger">{{
                    row.item.dueDate | formatDate("LL")
                    }}</span>
                </template>
              </b-table>
            </div>
            <div class="flex justify-center">
              <b-spinner v-if="loading" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Ongoing Invoices -->
    <div class="row" v-if="activeTab == 'tab2'">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="ongoing" :fields="ongoingfields" responsive="sm" :per-page="perPage"
                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">
                <template v-slot:cell(Edit)="row">
                  <router-link :to="`/edit-pharmacy-invoice/${row.item.id}`" class="btn btn-sm  btn-warning">
                    <i class="fa fa-pen" aria-hidden="true" title="Edit"></i></router-link>
                </template>

                <template v-slot:cell(clientName)="row">
                  {{ row.item.clientFirstName + " " + row.item.clientLastName }}
                </template>

                <template v-slot:cell(employeeName)="row">
                  {{
                  row.item.employeeFirstName + " " + row.item.employeeLastName
                  }}
                </template>

                <template v-slot:cell(DueDate)="row">
                  <span class="badge badge-pill badge-danger">{{
                    row.item.dueDate | formatDate("LL")
                    }}</span>
                </template>
              </b-table>
            </div>
            <div class="flex justify-center">
              <b-spinner v-if="loading" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Complete Invoice -->
    <div class="row" v-if="activeTab == 'tab3'">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="invoice_complete" :fields="invoicecompletefields" responsive="sm" :per-page="perPage"
                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">
                <template v-slot:cell(Edit)="row">
                  <router-link :to="`/edit-pharmacy-invoice/${row.item.id}`" class="btn  btn-sm btn-warning">
                    <i class="fa fa-pen" aria-hidden="true" title="Edit"></i></router-link>
                </template>

                <template v-slot:cell(clientName)="row">
                  {{ row.item.clientFirstName + " " + row.item.clientLastName }}
                </template>

                <template v-slot:cell(employeeName)="row">
                  {{
                  row.item.employeeFirstName + " " + row.item.employeeLastName
                  }}
                </template>

                <template v-slot:cell(DueDate)="row">
                  <span class="badge badge-pill badge-danger">{{
                    row.item.dueDate | formatDate("LL")
                    }}</span>
                </template>
              </b-table>
            </div>
            <div class="flex justify-center">
              <b-spinner v-if="loading" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Manage Employees",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      activeTab: "tab1",
      tableData: [],
      ongoing: [],
      invoice_complete: [],

      title: "Invoices",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "invoiceNumber", sortable: true },
        { key: "clientName", label: "Client Name", sortable: true },
        { key: "serviceOfferedText", sortable: true },
        { key: "cost" },
        { key: "employeeName", label: "Employee Name" },
        { key: "DueDate" },
        { key: "Edit" }
      ],
      ongoingfields: [
        { key: "invoiceNumber", sortable: true },
        { key: "clientName", label: "Client Name", sortable: true },
        { key: "serviceOfferedText", sortable: true },
        { key: "cost" },
        { key: "totalPaid" },
        { key: "balance" },
        { key: "employeeName", label: "Employee Name" },
        { key: "DueDate" },
        { key: "Edit" }
      ],
      invoicecompletefields: [
        { key: "invoiceNumber", sortable: true },
        { key: "clientName", label: "Client Name", sortable: true },
        { key: "serviceOfferedText", sortable: true },
        { key: "totalCost" },
        { key: "employeeName", label: "Employee Name" },
        { key: "DueDate" },
        { key: "Edit" }
      ],
      loading: false
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  mounted() {
    this.getAllInvoices();
    // Set the initial number of items
    this.totalRows = this.tableData.length;
  },
  methods: {
    getAllInvoices() {
      this.$store.dispatch("invoice/getAllInvoice").then((response) => {
        // console.log(response.data);
        this.tableData = response.data.filter(
          (invoice) => invoice.statusId == 0
        );
        this.ongoing = response.data.filter((invoice) => invoice.statusId == 1);
        this.invoice_complete = response.data.filter(
          (invoice) => invoice.statusId == 2
        );
      });
    },

    changeTab(tab) {
      this.activeTab = tab;
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>
